import React, { useState } from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { Slide } from 'react-reveal';
import Wave from 'react-wavify';
import { InPageBot } from "@leadoo/react-bot";

// components
import { TestimonialCard }  from "../components/testimonial";

// images
import banner_img from "../images/partner.svg";
import monitor from '../images/partners_logor/1_monitor.jpg';
import jeeves from '../images/partners_logor/2_jeeves.jpg';
import proclient from '../images/partners_logor/proclient.jpg';
import zoezi from '../images/partners_logor/10_zoezi.jpg';
import trinax from '../images/partners_logor/13_trinax.jpg';
import gc_solution from '../images/partners_logor/9_gc.jpg';
import vagfas from '../images/partners_logor/vagfas.jpg';
import work_system from '../images/partners_logor/work_system.jpg';
import lr_logo from '../images/partners_logor/lr.jpg';
import swinx from '../images/partners_logor/5_swinx.jpg';
import docup from '../images/partners_logor/7_docup.jpg';
import kr_system from '../images/partners_logor/kr_system.jpg';

const BOT_CODE = "l8QZLG6U";

function Partner() {
    const [chatbot_1, setChatbot_1] = useState(false);

    return(
        <Layout>
            <Helmet>
                <title>Partner | Crediflow</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="Crediflow har ett komplett partnerprogram för dig som vill kunna leverera de bästa fakturatjänsterna på marknaden till dina kunder." />
                <meta name="keywords" content="fakturatjänster | återförsäljare | partnerprogram" />
                <meta property="og:title" content="Crediflow AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="Partnerprogram för dig som vill kunna leverera de bästa fakturatjänsterna på marknaden." />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/partner" />
                <link rel="cannonical" href="https://www.optosweden.se/partner" />
            </Helmet>

            <section>
                <div className="w-screen bg-white border-t border-gray-100">
                    <div className="container mx-auto px-4 xl:px-24">
                        <div className="grid lg:grid-cols-2 py-24 gap-20">
                            <Slide left>
                                <div className="flex flex-col items-start justify-center">
                                    <h1 className="title-font font-semibold text-3xl text-blue-custome">Vill du kunna leverera de bästa fakturatjänsterna på marknaden till dina kunder?</h1>
                                    <p className="leading-relaxed mt-8 text-lg text-gray-600">
                                    
                                        Crediflow har ett komplett partnerprogram både för integratörer 
                                        så väl som rena återförsäljare. Med moderna API'er kan du presentera 
                                        vår lösning som din egen, eller använd dig av våra partnersäljare 
                                        för att få det stöd som behövs för att dina affärer ska ta fart.
                                        <br/><br/>
                                        Kontakta oss så ser vi hur vi tillsammans kan erbjuda dina 
                                        kunder den bästa lösningen! 
                                    </p>
                                    <button onClick={() => setChatbot_1(true)} className="px-10 py-2 rounded-full mt-10 text-white bg-blue-custome shadow-md hover:opacity-90">Kom i kontakt</button>
                                    {chatbot_1 
                                        ? 
                                            <div className="h-full w-full mt-10">
                                                <InPageBot code={BOT_CODE} seamless/>
                                            </div> 
                                        : "" 
                                    }
                                </div>
                            </Slide>
                            <Slide right>
                                <div className="w-full p-4 mx-auto">
                                    <img className="w-full" src={banner_img} alt="service och support optosweden" />
                                </div>
                            </Slide>
                           

                        </div>
                    </div>
                    <Wave 
                        className="-mb-2"
                        fill='#F3F4F6'
                        paused={false}
                        options={{
                        height: 20,
                        amplitude: 50,
                        speed: 0.15,
                        points: 3
                        }}
                    />
                </div>
            </section>


            <section className="bg-gray-100">
                <div className="container mx-auto px-4 xl:px-24">
                    <h1 className="text-center text-blue-custome title-font font-semibold text-3xl">Så här tycker våra partners!</h1>
                    <p className="mt-4 text-center text-gray-600">Gör som andra, upplev helheten!</p>
                    <div className="mt-14 pb-32">
                        <div className="grid  lg:grid-cols-2 xl:grid-cols-3 gap-8">
                            <TestimonialCard  company="Monitor ERP System AB" logo={monitor}>
                                Crediflows lösning CrossState ger ett helt integrerat digitalt 
                                fakturaflöde i MONITOR och hjälper våra kunder att ytterligare 
                                effektivisera sina verksamheter.
                                <br/><br/>
                                Tjänsten för fakturatolkning och mottagning av e-faktura 
                                aktiveras enkelt direkt i MONITOR och gör att våra kunder 
                                nu sparar dyrbar tid som de kan lägga på mer värdeskapande 
                                aktiviteter.
                                <br/><br/>
                                Samarbetet med Crediflow, en lyhörd och engagerad leverantör, 
                                ger hög kvalitet i vår slutprodukt och är en viktig del 
                                för att upprätthålla ett affärssystem i världsklass.
                            </TestimonialCard>

                            <TestimonialCard  company="Jeeves Information Systems AB" logo={jeeves}>
                                Tillsammans med Crediflow kan vi på Jeeves hjälpa våra kunder 
                                att öka sin produktivitet genom att lägga sin tid på 
                                värdeskapande och utvecklande uppgifter.
                                <br/><br/>
                                Hur gör vi det? Jo, genom att automatisera viktiga 
                                ekonomiflöden som inkommande och utgående fakturor där 
                                Crediflows produkt CrossState och Jeeves ERP löser 
                                flödet snabbt och smärtfritt åt användaren. När fakturor 
                                och inköpsorder matchar varandra skapar vi tillsammans 
                                ett helt automatiserat flöde hela vägen till betalning.
                                <br/><br/>
                                Tack vare Crediflows gedigna kompetens kan vi erbjuda 
                                våra kunder en tjänst som omfattar både en bra produkt 
                                och ett fantastiskt team bakom spakarna.
                            </TestimonialCard>

                            <TestimonialCard  company="Proclient System AB" logo={proclient}>
                                I dagens digitala samhälle ställs det fler och 
                                fler krav på automatiserade lösningar och smarta integrationer.
                                <br/><br/>
                                Proclient har sedan 2014 tillsammans med Crediflow 
                                levererat en uppskattad tjänst till kunder över 
                                hela landet som effektiviserar deras fakturahantering. 
                                Med CrossState eVoyce och vår Plusfunktion får 
                                våra kunder fakturan registrerad och konterad rätt in i Pyramid.
                                <br/><br/>
                                Kunderna kan också välja till extern hantering 
                                vilket gör att första gången de ser fakturan 
                                är den redan konterad och klar inne i Pyramid.
                            </TestimonialCard>

                            <TestimonialCard company="KR System" logo={kr_system}>
                                Baserat på feedback och önskemål från våra kunder 
                                har vi tillsammans med Crediflow tagit fram en 
                                unik plattform i Pyramid Business Studio 
                                för digitalt fakturaflöde som kombinerar det 
                                bästa av två världar:
                                <br/>- Branschledande tolkningsteknik genom AI och Maskininlärningsteknik
                                <br/>- Bibehållen full kontroll, med attest och kontering direkt i Pyramid 
                                <br/><br/>
                                Effektivt, skalbart och i ett gränssnitt 
                                designat för snabbhet. <a className="text-blue-custome hover:text-blue-800" href="https://krsystem.se/affarssystem/pyramid-funktion/elektronisk-fakturahantering/" target="_blank" rel="noreferrer">Läs mer om KR 
                                Systems lösning Elektronisk fakturahantering (EFH)</a>
                                <br/><br/>
                                Vi på KR System har över 500 kunder under vår 
                                förvaltning där vi dagligen hjälper till 
                                att effektivisera och förenkla processer. OptoSwedens gedigna 
                                kompetens och engagemang gör att vi tillsammans kan 
                                erbjuda våra kunder en bra produkt och ett starkt team.
                            </TestimonialCard>

                            <TestimonialCard  name="Mikael Håkansson" company="Zoezi AB" logo={zoezi}>
                                Att få till en smidig lösning där fakturor tolkas 
                                automatiskt och kommer in direkt in i Zoezi-affärssystem 
                                där det enkelt går kolla av dem och sen bekräfta 
                                för automatisk betalning är otroligt viktigt för 
                                oss och våra kunder.
                                <br/><br/>
                                Kunderna kan tack vare detta minimera den 
                                administrativa tiden och lägga det på sin 
                                kärnverksamhet istället. Samarbetet med Crediflow 
                                har fungerat väldigt bra, får snabb respons när 
                                vi har frågor och har fått stöttning i under 
                                hela utvecklingsarbetet.
                            </TestimonialCard>

                            <TestimonialCard  name="Fredrik Yli-Luopa" company="Trinax AB" logo={trinax}>
                                I Trinax Tid- &amp; Projekt så kan våra kunder skapa 
                                offerter, tidrapporter, fakturor, projektuppföljning 
                                och nu med vår integrerade lösning tillsammans 
                                med Crediflow så kan vi även erbjuda en komplett 
                                lösning för inkommande och utgående fakturor.
                                <br/><br/>
                                Detta gör arbetet för våra kunder mycket effektivt.
                            </TestimonialCard>

                            <TestimonialCard  name="Mathias Bohlin" company="GC Solutions AB" logo={gc_solution}>
                                Vi har tillsammans med Crediflow skapat en sömlös 
                                integration mellan Garp och ett av marknadens 
                                bästa leverantörsfakturasystem, helt rätt i 
                                tiden tycker vi, då denna typ av lösning 
                                efterfrågas allt mer, så att våra kunder ges 
                                en effektiv och platsoberoende kontroll, 
                                övervakning och attesterings-process.
                            </TestimonialCard>

                            <TestimonialCard  name="Sebastian Öberg" company="Vägfas AB" logo={vagfas}>
                                Crediflow är kompetenta och trevliga att arbeta 
                                med, alltid villiga att göra anpassningar 
                                och erbjuda hjälp.
                                <br/><br/>
                                Vi är mycket nöjda kunder sedan 4 år, en 
                                smidig och toppen leverantör av utskrifter 
                                och e-fakturor helt enkelt.
                            </TestimonialCard>

                            <TestimonialCard company="At Work Systems AS" logo={work_system}>
                                Som utviklingspartner nyter vi godt av deres 
                                meget omfattende kompetanse og erfaring 
                                slik at vi sammen kan utvikle de beste 
                                løsningene for våre ulike kundegrupper.
                            </TestimonialCard>

                            <TestimonialCard  name="Martin Johansson" company="LR Revision &amp; Redovisning" logo={lr_logo}>
                                Vi sökte en partner som dels har mycket hög 
                                kompetens inom tolkning, dels kan vara en 
                                strategisk partner kring automatisering och 
                                digitalisering av våra tjänster.
                            </TestimonialCard>

                            <TestimonialCard company="Swinx AB" logo={swinx}>
                                Det Crediflow erbjuder i form av teknik, 
                                säkerhet, tillgänglighet och support 
                                är av yppersta klass och ovärderligt för oss som partner.
                            </TestimonialCard>

                            <TestimonialCard company="DocUp AB" logo={docup}>
                                Det DocUp erbjuder i form av smidighet 
                                och effektivitet till våra användare 
                                hade inte varit möjligt utan Crediflows 
                                tjänster och API integrationen.
                            </TestimonialCard>

                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    );
}
export default Partner;